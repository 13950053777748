<div *ngIf="form" class="m-4">
  <h4 class="overline-o2 grey-700">{{'plugins.applications' | translate }}</h4>

  <!-- INFO BANNER -->
  <div *ngIf="form.disabled" class="flex items-center justify-between bg-primary-100 py-4 px-6 my-4">
    <div class="text-left mr-4">
      <h3 class="title-t2">{{'organizationData.enlarge' | translate}}</h3>
      <p class="body-b2">{{'organizationData.enlargeDetail' | translate}}</p>
    </div>
    <a [href]="'mailto:' + CONTACT_EMAIL" class="w-1/3">
      <app-button-secondary type="button" class="flex items-center">
        <mat-icon svgIcon="arrow-right" color="primary" class="-mb-1"></mat-icon>
        {{'organizationData.contactTeam' | translate}}
      </app-button-secondary>
    </a>
  </div>

  <div class="-ml-2 mt-6 flex flex-col items-start">
    <!-- CARGOX UTILIZA EL AVAILABLE EN EL CHECK EN VEZ DEL ENABLED PARA QUE SE LO HABILITEN DESDE BO -->
    <app-toggle-card [control]="form.get('cargox.available') | cast" [icon]="icons.cargox" [label]="'plugins.cargox' | translate" [description]="'plugins.cargoXDescription' | translate" class="w-full"></app-toggle-card>
    <app-toggle-card [control]="form.get('iaDataExtraction.enabled') | cast" [icon]="icons.iaDataExtraction" [label]="'plugins.iaDataExtraction' | translate" [description]="'plugins.iaDescription' | translate" data-test="iaDataExtractionCheck" class="w-full"></app-toggle-card>
    <app-toggle-card [control]="form.get('splitDocuments.enabled') | cast" [icon]="icons.draftDocuments" [label]="'plugins.iaDocumentDivision' | translate" [description]="'plugins.iaDocumentDivisionDescription' | translate" class="w-full"></app-toggle-card>

    <!--TOGGLE DOCS-->
    <app-toggle-card [control]="form.get('edocs.enabled') | cast " [icon]="icons.edocs" [label]="'plugins.edocs' | translate" [description]="'plugins.edocsDescription' | translate" class="w-full">
      <div class="bg-grey-100 w-full bg-grey-100">
        <div class="flex items-center justify-between border-top mt-6 px-6">
          <h3>{{'edocs.contractedDocuments' | translate}}</h3>
          <app-button-secondary class="flex items-center justify-between">
            <mat-icon [svgIcon]="'plus'" color="primary" class="mr-2"></mat-icon>
            <span class="pb-6">{{'buttons.addNew' | translate}}</span>
          </app-button-secondary>
        </div>
      </div>
    </app-toggle-card>

    <!--TOGGLE POWERBI-->
    <app-toggle-card [control]="form.get('powerBi.enabled') | cast " [isExpandable]="true" [icon]="icons.powerBi" [label]="'plugins.powerBi' | translate" [description]="'plugins.powerBiDescription' | translate" [panelExpanded]="true" class="w-full">
      <div class="bg-grey-100 w-full bg-grey-100">
        <div class="w-full border-top mt-6">
          <div class="w-1/2 mt-4">
            <app-input [type]="'text'" [control]="form.get('powerBi.url')" [label]="('plugins.url' | translate)" [placeholder]="'plugins.urlPlaceholder' | translate" class=" mt-4"></app-input>
          </div>
        </div>
      </div>
    </app-toggle-card>
  </div>
</div>
