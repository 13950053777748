import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, NonNullableFormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { CacheService } from '../../../front/src/app/core/services/cache.service';
import { CONTACT_EMAIL } from '../../models/constants';
import { IntegrationsData, OrganizationExtended } from '../../models/main';
import { ApiService } from '../../services/api.service';


@Component({
  selector: 'app-applications-form[organization]',
  templateUrl: './applications-form.component.html',
  styleUrls: ['./applications-form.component.scss'],
})
export class ApplicationsFormComponent implements OnInit {

  @Input() organization!: OrganizationExtended;
  CONTACT_EMAIL = CONTACT_EMAIL;
  form!: FormGroup;
  icons = {
    'iaDataExtraction': 'spark-line',
    'draftDocuments': 'details',
    'cargox': 'cargox',
    'edocs': 'edoc',
    'powerBi': 'growth',
  };

  constructor(private fb: NonNullableFormBuilder, private api: ApiService, private ar: ActivatedRoute, private cache: CacheService) {
  }

  ngOnInit(): void {
    this.ar.params.subscribe((params) => {
      if (params.id) {
        // si hay params.id estoy en BO, cojo el get organization
        this.api.getOrganization(params.id).subscribe(res => {
          this.organization = res;
          this.form = this.createPluginForm(this.organization, false);
        });
      } else {
        // si no hay params, seteo la org desde el user
        this.cache.getUser().subscribe(user => {
          if (user.organization) this.organization = user.organization;
          this.form = this.createPluginForm(this.organization, true);
        });
      }
    });
  }

  createPluginForm(data: OrganizationExtended, disabled: boolean) {
    const form = this.fb.group({
      cargox: this.fb.group({
        available: [data.integrations?.cargox?.available || false],
        enabled: [data.integrations?.cargox?.enabled],
      }),
      iaDataExtraction: this.fb.group({
        enabled: [data.integrations?.iaDataExtraction?.enabled],
      }),
      splitDocuments: this.fb.group({
        enabled: [data.integrations?.splitDocuments?.enabled],
      }),
      edocs: this.fb.group({
        enabled: [data.integrations?.edocs?.enabled],
      }),
      powerBi: this.fb.group({
        enabled: [data.integrations?.powerBi?.enabled],
        url: [data.integrations?.powerBi?.url],
      }),
    });

    if (disabled) form.disable();

    form.valueChanges.subscribe(() => this.onPluginChange());
    return form;
  }

  onPluginChange() {
    const data: { integrations: IntegrationsData } = { integrations: this.form.getRawValue() };

    this.api.editOrganizationData(data, this.organization.id).subscribe((res) => {
      this.api.log('toast.saved');
      this.organization = res;
    });
  }
}
